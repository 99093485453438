
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class ColorSelector extends Vue {
  @Prop([String])
  readonly value: string | undefined;

  @Prop(String)
  readonly caption: string | undefined;

  @Prop()
  readonly readonly: any;

  showSelector = false;
  color = '';

  store() {
    this.showSelector = false;
    this.$emit('input', this.color)
  }
}
